.theme-switch {
  display: inline-block;
  width: 2.4em; /*prev */
  height: 1.5em; /*prev 25px*/
  position: absolute;
  top: 1.75em;
  right: 0.6em;
  transform: translateY(-50%);
}

.theme-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.theme-switch label {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--light-text);
  border-radius: 1.36em;
}

.theme-switch input:checked + label {
  background-color: var(--light-bg);
}

.theme-switch label::before {
  position: absolute;
  content: '';
  height: 1.25em;
  width: 1.25em;
  left: 6%;
  bottom: 50%;
  background-color: var(--light-bg);
  background-size: var(--background-sizing);
  background-image: url('./assets/sun.png');
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  transform: translate(-50%, 50%) translateX(0.575em);
  animation: roll-out var(--animation-time) forwards var(--bezier);
}

.theme-switch input:checked + label::before {
  background-color: var(--dark-bg);
  background-size: var(--background-sizing);
  background-image: url('./assets/moon.png');
}

.theme-switch input:checked + label::before {
  transform: translateX(0px);
  animation: roll-in var(--animation-time) forwards var(--bezier);
}

@keyframes roll-out {
  from { transform: translate(-50%, 50%) translateX(0.575em) rotate(-180deg); }
  to { transform: translate(-50%, 50%) translateX(1.5125em) rotate(0deg); } /* Adjusted for movement */
}

@keyframes roll-in {
  from { transform: translate(-50%, 50%) translateX(1.5125em) rotate(180deg); }
  to { transform: translate(-50%, 50%) translateX(0.575em) rotate(0); }
}
