:root {
  --dark-bg: #464b51;
  --light-bg: #E5EBEF;
  --dark-text: #FFF;
  --light-text: #333;
  --bezier: cubic-bezier(0,.7,.3,1);
  --background-sizing: 80%;
  --animation-time: 0.2s;
  --max-width: 65rem;
  --box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.5);
  overflow-x: hidden;
}

/* Phones in portrait orientation */
@media (max-width: 599px) { /* Adjusted to 599px */
  :root {
    font-size: 18px; /* Smaller base font size for smaller screens */
  }
  /* Hide scrollbar for Webkit browsers */
  body::-webkit-scrollbar {
    display: none;
  }
  /* For other browsers, make scrollbar transparent */
  body {
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none;  /* For Internet Explorer and Edge */
  }
}

/* Tablets */
@media (min-width: 600px) {
  :root {
    font-size: 17px; /* Default base font size */
  }
}

/* Desktops */
@media (min-width: 900px) {
  :root {
    font-size: 16px; /* Slightly larger base font size for larger screens */
  }
  body {
    /*shown on desktop*/
    overflow-y: auto;
  }
}

/* Tablets */
@media (min-width: 600px) and (max-width: 899px) {
  /* Hide scrollbar for Webkit browsers */
  ::-webkit-scrollbar {
    display: none;
  }
  /* For other browsers, make scrollbar transparent */
  body {
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none;  /* For Internet Explorer and Edge */
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(1rem + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p, h2, h3, h4, h5, h6, li {
  font-family: 'Neue Montreal', sans-serif;
}

h2 {
  font-size: 2em;
  font-weight: 500;
  text-decoration: underline;
}

h3 {
  font-size: 1.5em;
  font-weight: 500;
}

p {
  font-size: 1.2em;
  text-align: justify;
  hyphens: auto;
}

a {
  font-weight: 600;
}

.light .link {
  color: var(--light-text);
}

.dark .link {
  color: var(--dark-text);
}

li {
  font-size: 1.2em;
}

.light {
  background-color: var(--light-bg);
  color: var(--light-text);
}

.dark {
  background-color: var(--dark-bg);
  color: var(--dark-text);
}

.col {
  padding: 1em;
}

h1 {
  font-family: 'Monaco', sans-serif;
  font-size: 4em;
}

.container {
  width: 100%;
  max-width: var(--max-width);
  padding: 0 1em;
}

.nav-link:hover, a:hover {
  cursor: pointer;
}

.custom-navbar-brand {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  padding-right: 40px;
}

@media (min-width: 992px) {
  .custom-navbar-brand {
    flex-grow: 0;
    padding-right: 0;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3485ff;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0D6EFD;
}
