.light .card {
  background-color: transparent;

}
  
.dark .card {
  background-color: transparent;
}

.card {
  box-shadow: var(--box-shadow);
}

.card {
  width: 100%;
  height: 27rem;
  display: flex;
  flex-direction: column;
}

@media (max-width: 437px) {
  .card {
    height: 35rem;
  }
}

.project-card {
  position: relative;
  overflow: hidden;
}

.project-card-img-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.project-card-img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform .5s ease;
}

.project-card:hover .project-card-img {
  transform: scale(1.1);
}

.project-card-body {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  opacity: 1;
  backdrop-filter: blur(2px);
}

.dark .project-card-body h2, .dark .project-card-body h3, .dark .project-card-body p {
  color: var(--dark-text);
}

.light .project-card-body h2, .light .project-card-body h3, .light .project-card-body p {
  color: var(--light-text);
}

.light .project-card-body {
  background: rgba(255, 255, 255, 0.75);
}

.dark .project-card-body {
  background: rgba(0, 0, 0, 0.75);
}

.modal-997px {
  width: 100%;
  max-width: var(--max-width);
  margin: auto;
}

.modal-body-padding {
  padding: 1rem;
}

.light .modal-bg-color {
  background-color: var(--light-bg);
}

.dark .modal-bg-color {
  background-color: var(--dark-bg);
}

.image {
  width: 80%;
  height: auto;
  max-height: 50vh;
  object-fit: cover;
  margin: auto;
  padding: 1rem;
  display: block;
  border-radius: 25px;
}

body.modal-open {
  padding-right: 15px;
}

.modal-img {
  width: 90%;
  height: auto;
  max-height: 80vh;
  object-fit: cover;
  padding: 1rem;
  margin: auto;
  display: block;
  border-radius: 25px;
}
